import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';

import { useSearchParams, useNavigate } from 'react-router-dom';

import * as Sentry from "@sentry/react";
import { datadogRum } from '@datadog/browser-rum';

import { GoogleLogin } from '@react-oauth/google';

function Login({ onUser, headerText, visible, onModalClose }) {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [waitlist, setWaitlist] = useState(false);

    const handleCloseModal = () => {
        // setModalOpen(false);
        onModalClose();
      }

    useEffect(() => {
        // if (!user) {
        const token = localStorage.getItem('token');
        if (token) {
            onSuccess(token);
        }
        // }
    }, []);

    const onSuccess = (credential) => {
        let payload = {
            tokenId: credential
        };

        const inviteCode = searchParams.get('invite');
        if (inviteCode) {
            payload.inviteCode = inviteCode;
        }

        fetch(`${process.env.REACT_APP_API_URL}/auth/login/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((authRes) => {
                if (authRes.ok) {
                    authRes.json().then((user) => {
                        console.log(credential);
                        if (inviteCode) {
                            navigate('/'); // redirect from /accept to home
                        }

                        //setToken(credential);
                        localStorage.setItem('token', credential);

                        if (process.env.NODE_ENV != 'development') {
                            Sentry.setUser({
                                id: user.id,
                                email: user.email,
                                ip_address: '{{auto}}'
                            });

                            datadogRum.setUser({
                                id: user.id,
                                //name: 'John Doe',
                                email: user.email
                            });
                        }

                        onUser(user);
                    });
                }
                else if (authRes.status == 403) {
                    setWaitlist(true);
                    // setModalOpen(true);
                }
            });
    };

    const refreshTokenSetup = (res) => {
        let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

        const refreshToken = async () => {
            const newAuthRes = res.reloadAuthResponse();
            console.debug('refreshing token');
            console.debug(newAuthRes);
            refreshTiming = (newAuthRes.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

            setTimeout(refreshToken, refreshTiming); // for next session expiration
        }

        setTimeout(refreshToken, refreshTiming); // for first session expiration
    }

    const doFakeLogin = async () => {
        const token = 'testing-11';

        onSuccess(token);
    }

    const createTestUser = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/test-users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (res.ok) {
            const user = await res.json();

            onSuccess(`testing-${user.id}`);
        }
    }

    const onFailure = (res) => {
        console.log('[Login failed] res: ', res);
    };

    let fakeLogin;
    if (process.env.REACT_APP_ENV == 'development') {
        fakeLogin =
            headerText == 'Sign In' ?
                <Button onClick={doFakeLogin}>Fake Login</Button>
                :
                <Button onClick={createTestUser}>Create Test User</Button>;
    }

    return (
        <Modal
            open={visible}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={modalStyle}>
                {waitlist ?
                    <Typography variant='h5' textAlign='center' sx={{ m: 4, mt: 6 }}>
                        Thanks for registering! We are currently running a limited beta to iron out any kinks.
                        We've added your name to the waitlist and will let you know when we are ready to widen
                        things up.
                    </Typography>
                    :
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{ marginBottom: 4 }}>
                            {headerText}
                        </Typography>
                        <GoogleLogin
                            onSuccess={(res) => onSuccess(res.credential)}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            useOneTap
                            auto_select
                        />
                        {fakeLogin}
                    </Box>
                }
            </div>
        </Modal>
    );
}

export default Login;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 350,
    border: '2px solid #000',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
  };