import Grid from '@mui/material/Grid';

import React, { useState, useEffect, useContext, Fragment } from 'react';

import { FireContext } from '../App';

import Balance from './Balance.js';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

import { Link as RouterLink } from "react-router-dom";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';

import SpendingByDay from './SpendingByDay.js';
import SpendingByCategory from './SpendingByCategory.js';
import Transactions from './Transactions';
import Accounts from './Accounts';

const Widgets = ({ user }) => {

  const [timePeriod, setTimePeriod] = useState('mtd');

  const { fiAge } = useContext(FireContext);

  const [netWorth, setNetWorth] = useState();
  const [greeting, setGreeting] = useState();

  const today = new Date();
  let monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let todayMinus90 = new Date();
  todayMinus90.setDate(todayMinus90.getDate() - 90);
  let todayMinus180 = new Date();
  todayMinus180.setDate(todayMinus180.getDate() - 180);

  monthStart = monthStart.toISOString().split('T')[0];
  todayMinus90 = todayMinus90.toISOString().split('T')[0];
  todayMinus180 = todayMinus180.toISOString().split('T')[0];

  const [reportBegin, setReportBegin] = useState(monthStart);
  const [reportEnd, setReportEnd] = useState(monthEnd);

  const formatCurrency = (value) => {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  }

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      setGreeting('morning');
    } else if (hour < 18) {
      setGreeting('afternoon');
    } else {
      setGreeting('evening');
    }
  }, []);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const getNetWorth = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/net-worth`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      setNetWorth(data.netWorth);
    }

    getNetWorth();
  }, []);

  const handleTimePeriodChange = (event, value) => {
    //console.log('time period change', value);

    if (value !== null) {
      setTimePeriod(value);
    }

    switch (value) {
      case 'mtd':
        console.log('mtg');
        console.log(monthStart);
        setReportBegin(null);
        setReportEnd(null);
        break;

      case '90days':
        console.log('90days');
        setReportBegin(todayMinus90);
        setReportEnd(monthEnd);
        break;

      case '180days':
        console.log('180days');
        setReportBegin(todayMinus180);
        setReportEnd(monthEnd);
        break;
    }
  }

  return (
    <Grid container spacing={2} mx='auto'>
      <Grid item xs={12}>
        <Typography variant="h4" textAlign="center">
          Good {greeting}, {user?.person?.firstName}
        </Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <Card>
          <CardContent sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography color="textSecondary" textAlign="center">
              FI AGE
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h2"
              textAlign="center"
            >
              {fiAge > 0 ? fiAge : <span>&infin;</span>}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={5}>
        <Card>
          <CardContent>
            <Typography
              color="textSecondary"
              textAlign="center"
            >
              NET WORTH
            </Typography>
            <Typography
              color="textPrimary"
              variant="h2"
              textAlign="center"
            >
              {formatCurrency(netWorth)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3} xs={12}>
        <Balance />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography textAlign='center' variant='h6'>Discretionary Spending vs Budget</Typography>
        <SpendingByDay />
      </Grid>
      <Grid item md={6} xs={12}>
        {/* <Box display="flex" justifyContent="flex-end" marginBottom={5}>
            <ToggleButtonGroup
              color="primary"
              value={timePeriod}
              exclusive
              onChange={handleTimePeriodChange}
              aria-label="Time Period"
            >
              <ToggleButton value="mtd">This Month</ToggleButton>
              <ToggleButton value="90days">Last 90 days</ToggleButton>
              <ToggleButton value="180days">Last 180 days</ToggleButton>
            </ToggleButtonGroup>
          </Box> */}
        <SpendingByCategory begin={reportBegin} end={reportEnd} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography textAlign={'center'} variant='h6'>Recent Transactions</Typography>
        <Transactions size="small" />
        <Box width='100%' textAlign='center'>
          <Link
            component={RouterLink}
            to="/activity"
            textAlign="center"
          >
            View all
          </Link>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography textAlign={'center'} variant='h6'>Account Balances</Typography>
        <Accounts size="small" />
        <Box width='100%' textAlign='center'>
          <Link
            component={RouterLink}
            to="/networth"
            textAlign="center"
          >
            View all
          </Link>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Widgets;