import { Fragment, useState, useEffect } from "react";
import LinkStatus from "./LinkStatus.js";
import PlaidLinkWithOAuth from "./PlaidLinkWithOAuth.js";
import eventBus from '../EventBus.js';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function Settings() {

    const [items, setItems] = useState([]);

    const token = localStorage.getItem('token');

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/accounts/items`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const json = await res.json();
            setItems(json);
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    return (
        <Fragment>
            <h1>Settings</h1>
            <h2>Linked Accounts</h2>
            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                {items.map(item =>
                    <Box sx={{ display: 'flex', alignItems: 'center' }} key={item.itemId}>
                        <Box sx={{ minWidth: 200 }}>
                            <div>
                                <img width="35px" height="35px" src={`data:image/png;base64,${item.institution?.logoBase64}`} />

                                {item.status == -1 && item.errorCode == 'ITEM_LOGIN_REQUIRED' &&
                                    <PlaidLinkWithOAuth itemId={item.itemId} onLinkSuccess={() => getItems()} />
                                }
                            </div>
                        </Box>
                    </Box>
                )}
            </Stack>
        </Fragment>
    );
}